<template>
  <div class="order-now-button">
    <a v-if="plan.checkout_url" :href="plan.checkout_url" role="button">Order now!</a>
    <a v-else href="#" @click.prevent="$router.push({name: 'contact'})" role="button">Contact Us</a>
  </div>
</template>

<script>
export default {
  name: "order-now-button",
  props: ["plan"]
};
</script>

<style lang="scss" scoped>
.order-now-button {
  a {
    width: 100%;
  }
}
</style>