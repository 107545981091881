<template>
  <navigation />
</template>

<script>
import navigation from '@/components/shared/navigation'
export default {
  name: "Header",
  components: {
    navigation
  }
}
</script>