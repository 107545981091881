<template>
  <footer>
    <div class="container">
      <div class="left-content">
        <div><img src="@/assets/images/logo/logo-square-dark.png" /></div>
      </div>
      <div class="right-content">
        <ul>
          <li> Glasgow, UK
            <br />
            <a href="email:support@activeinformationdesign.com" class="secondary">support@{{ $productDomain }}</a>
            <br />
            <!-- <a href="tel:+441414655505" class="secondary">0141 465 5505</a> -->
          </li>
          <li>
            <router-link :to="{ name: 'privacy-policy' }" class="secondary">Privacy Policy</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'terms-of-service' }" class="secondary">Terms of Service</router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  background-color: black;
  height: 180px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .left-content {
      display: flex;
      align-items: flex-end;
      gap: 30px;

      img {
        height: 100px;
      }
    }
  }

  ul {
    padding: 0;
  }
}

ul {
  font-size: 14px;

  li {
    list-style: none;
  }
}
</style>