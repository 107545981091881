<template>
  <app-header v-if="!hideHeader" :data-theme="theme" :class="theme" />
  <div class="content" :data-theme="theme" :class="theme">
    <router-view />
  </div>
  <app-footer v-if="!hideFooter" data-theme="dark" :class="theme" />
</template>

<script>
import AppHeader from "@/components/shared/app-header";
import AppFooter from "@/components/shared/app-footer";
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  computed: {
    theme() {
      return this.$route.meta.theme
        || "light";
    }, hideHeader() { return !!this.$route.meta.hideHeader; }, hideFooter() {
      return !!this.$route.meta.hideFooter;
    },
  },
}; </script>

<style lang="scss" scoped>
.content {
  min-height: calc(100vh - 100px - 180px);
}
</style>
