<template>
  <div class="container">
    <h1>How does it work?</h1>
    <p>Streaming used to be so much of a hassle, getting expensive cameras setup, then configuring a computer, having
      someone sit at the computer to run the stream. with {{ $appName }} it couldn't be simpler.
    </p>

    <ol>
      <li>Plug in {{ $productName }}</li>
      <li>Configure for your WIFI</li>
      <li>Add your camera</li>
      <li>Authenticate with Youtube</li>
      <li>Start Streaming!</li>
    </ol>

    <!-- <ol>
      <li>Receive {{ $productName }} in the post and a camera if you wanted one.</li>
      <li>Plugin Camera and configure for your wifi or plug in network cable to your network</li>
      <li>Plug {{ $productName }} into power and your audio feed if you have one.</li>
      <li>Configure {{ $productName }} for your wifi</li>
      <li>Goto streaming.local on your phone</li>
      <li>Add your details to the setup wizard</li>
      <li class="emph">Start Streaming!</li>
    </ol>
    <h4>Skill level required</h4>
    <table>
      <tr><th>Initial Setup</th><td>Minimal IT Skills, can setup wifi on a device</td></tr>
      <tr><th>Streaming</th><td>Basic IT, someone who can use a webpage</td></tr>
    </table>
    <h4>Requirements</h4>
    <p>There are only a few system requirements in order to operate {{ $productName }}:</p>
    <ul>
      <li>Internet Connection - at least 3Mbps upload speed</li>
      <li>Wifi - wifi to connect the cameras and box to your network</li>
      <li>Power for camera and box</li>
    </ul> -->
  </div>
</template>

<style lang="scss" scoped>
table {
  th {
    font-weight: bold;
  }
}

.emph {
  font-weight: 600;
}
</style>