<template>
  <header class="container">
    <hgroup>
      <h1>{{ $appName }}</h1>
      <h2>Plug in {{ $productName }} and video stream your next live event, anyone can do it!</h2>
    </hgroup>
    <p>
      <router-link :to="{ name: 'features' }" role="button">Show me More</router-link>
    </p>
  </header>
</template>

<style lang="scss" scoped>
header {
  margin-top: 30px;
  position: relative;
}

h2 {
  margin-top: 15px;
}
</style>