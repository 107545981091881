import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";

const app = createApp(App);
app.config.globalProperties.$appName = "ReadyCast";
app.config.globalProperties.$productName = "ReadyCast";
app.config.globalProperties.$productDomain = "readycast.app";
app.use(router);
app.mount("#app");

require("@/assets/styles/base.scss");
require("@/assets/styles/nav.scss");
require("@/assets/styles/mobile.scss");
require("@/assets/styles/override.scss");
