<template>
  <div class="container">
    <h1>Pricing</h1>
    <p>All prices are monthly and are based on minimum 12 month contract. We rent you all the hardware needed, so you
      don't need to worry about that.</p>
    <div class="grid pricing">
      <plan v-for="plan in plans" :key="`plan-${plan.name}`" :plan="plan" />
    </div>
    <h4>Support</h4>
    <p>We offer some enhanced support options on an as required basis</p>
    <table>
      <tr>
        <th>Phone Support</th>
        <td>£20 per hour</td>
      </tr>
      <tr>
        <th>Onsite Support</th>
        <td>£50 per hour</td>
      </tr>
    </table>
  </div>
</template>

<script>
import plan from "@/components/pricing/plan";
export default {
  name: "pricing",
  components: {
    plan,
  },
  data() {
    return {
      plans: [
        {
          name: "Silver",
          price: 40,
          id: "prod_LLgMUOfFEuTuZI",
          checkout_url: "https://buy.stripe.com/6oE5mB9e40U24ZacMY",
          features: [
            `Rental of ${this.$productName} hardware`,
            "Streaming software",
            "Email Support",
            "Use Existing RTSP Camera",
            "Own Audio Equipment",
          ],
        },
        {
          name: "Gold",
          price: 70,
          id: "prod_LLj7qHuyi4W5mO",
          checkout_url: "https://buy.stripe.com/aEUeXb0HybyGbnycMZ",
          features: [
            `Rental of ${this.$productName} hardware`,
            "Streaming software",
            "Email Support",
            "1 Network Camera",
            "at least 1080p Resolution",
            "Own Audio Equipment",
          ],
        },
        {
          name: "Platinum",
          price: 140,
          id: "prod_LLj8DpU330bh3o",
          checkout_url: "https://buy.stripe.com/aEUcP38a032afDO6oC",
          features: [
            `Rental of ${this.$productName} hardware`,
            "Streaming software",
            "Email Support",
            "2 Network Cameras",
            "at least 1080p Resolution",
            "Own Audio Equipment",
          ],
        }, {
          name: "Bespoke",
          price: undefined,
          features: [
            "Bespoke Audio/Video",
            "Site Installation",
            "Integration with Security"
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
