<template>
  <div class="hero-container">
    <div class="hero-background">
      <div class="hero-overlay" />
    </div>
    <app-header />
    <hero />
  </div>
  <features />
</template>

<script>
import appHeader from "@/components/shared/app-header";
import hero from "@/components/home/hero";
import features from "@/components/home/features";
export default {
  name: "App",
  components: {
    appHeader,
    hero,
    features,
  },
  computed: {
    theme() {
      return this.$route.meta.theme || "dark"
    }
  }
};
</script>

<style lang="scss">
.hero-container {
  position: relative;
  background: url(@/assets/images/jesus-loves-austin.jpg);
  background-size: 60%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: -150px;

  .hero-overlay {
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.6;
  }

}
</style>