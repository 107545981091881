<template>
  <div class="container">
    <h1>Contact Us</h1>
    <div class="grid">
      <div class="map">
        <img
          src="https://api.mapbox.com/styles/v1/mapbox/dark-v10/static/-4.2566,55.8543,6,0/600x400?access_token=pk.eyJ1Ijoic3Rld2FydG1ja2VlIiwiYSI6ImNsM2N3N3QzaDAxOXUzYnRoc2J5bXJtbGkifQ.u9JITmfAPuowO2totant9Q" />
      </div>
      <div class="form">
        Based in Glasgow, we can post the devices to you for self installation or we can provide installation, charged
        at an hourly rate.
        <div class="form">
          <input type="email" name="email" placeholder="email address" />
          <textarea name="message" placeholder="your message"></textarea>
          <button>Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin-bottom: 60px;

  .form {
    margin-top: 12px;
  }
}
</style>