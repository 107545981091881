<template>
  <div class="item" data-theme="dark" :class="{ 'with-features': featuresVisible }">
    <h2>{{ plan.name }}</h2>
    <p class="price"><span v-if="plan.price !== undefined">£{{ plan.price }}<span
          class="small">/month</span></span><span v-else>£Call</span></p>
    <ul class="features">
      <li v-for="feature in plan.features" :key="feature">{{ feature }}</li>
    </ul>
    <button id="features-toggle" role="button" class="secondary outline" @click="featuresVisible = !featuresVisible">
      More
    </button>
    <order-now-button :plan="plan" />
  </div>
</template>

<script>
import orderNowButton from "@/components/pricing/order-now-button";
export default {
  name: "plan",
  props: ["plan"],
  components: {
    orderNowButton,
  },
  data() {
    return {
      featuresVisible: false,
    };
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.9em;
}

.price {
  margin-top: 0px;
  font-weight: 600;
  font-size: 1.3em;

  .small {
    font-size: 0.5em;
  }
}

ul {
  padding: 5px;

  // text-align: left;
  li {
    list-style: none;
    font-size: 0.8em;
  }
}

.item {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 7px;
  background-color: var(--background-color);

  ul {
    flex: 1;
    padding: 0 10px;

    li {
      list-style: none;
      font-size: 0.8em;
    }
  }

  .order-now-button {
    display: flex;
  }
}

h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

p.price {
  font-size: 1.5em;
}

.features {
  display: inline;
}

#features-toggle {
  display: none;
}

@media only screen and (max-width: 700px) {
  .features {
    display: none;
  }

  .with-features {
    .features {
      display: inline;
    }

    #features-toggle {
      display: none;
    }
  }

  #features-toggle {
    display: inline;
    width: initial;
  }
}
</style>