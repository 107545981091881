<template>
  <nav class="container-fluid" :class="{ collapsed: closed, open: !closed }">
    <ul>
      <li>
        <router-link :to="{ name: 'home' }"><img class="logo" src="/logo-horizontal-dark.png" /></router-link>
      </li>
    </ul>
    <ul>
      <li><router-link :to="{ name: 'home' }" @click="closed = true" class="secondary">Home</router-link></li>
      <li><router-link :to="{ name: 'features' }" @click="closed = true" class="secondary">Features</router-link></li>
      <li><router-link :to="{ name: 'about-us' }" @click="closed = true" class="secondary">About Us</router-link></li>
      <li><router-link :to="{ name: 'pricing' }" @click="closed = true" class="secondary">Pricing</router-link></li>
      <li><router-link :to="{ name: 'how-does-it-work' }" @click="closed = true" class="secondary">How does it
          work?</router-link></li>
      <li><router-link :to="{ name: 'contact' }" @click="closed = true" class="secondary">Contact Us</router-link></li>
    </ul>
    <a class="toggle secondary" @click="closed = !closed">
      <AlignJustifyIcon size="3x" />
    </a>
  </nav>
</template>

<script>
import { AlignJustifyIcon } from '@zhuowenli/vue-feather-icons'

export default {
  name: "navigation",
  components: {
    AlignJustifyIcon
  },
  data() {
    return {
      closed: true,
    };
  },
};
</script>

<style lang="scss" scoped>
nav {
  position: relative;

  .logo {
    max-width: 150px;
  }

  .toggle {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  nav {
    .toggle {
      display: block;
    }
  }
}
</style>