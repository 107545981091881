import { createRouter, createWebHistory } from "vue-router";

import home from '@/views/home'
import aboutUs from '@/views/about-us'
import howDoesItWork from '@/views/how-does-it-work'
import pricing from '@/views/pricing'
import privacyPolicy from '@/views/privacy-policy'
import termsOfService from '@/views/terms-of-service'
import features from '@/views/features'
import contactUs from '@/views/contact-us'

const routes = [
    { path: '/', component: home, name: 'home', meta: { hideHeader: true, theme: "dark" } },
    { path: '/about-us', component: aboutUs, name: 'about-us' },
    { path: '/how-does-it-work', component: howDoesItWork, name: 'how-does-it-work' },
    { path: '/pricing', component: pricing, name: 'pricing' },
    { path: '/privacy-policy', component: privacyPolicy, name: 'privacy-policy' },
    { path: '/terms-of-service', component: termsOfService, name: 'terms-of-service' },
    { path: '/features', component: features, name: 'features' },
    { path: '/contact-us', component: contactUs, name: 'contact' },
    
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to, from) => {

    const theme = to.meta.theme === "dark" ? "dark" : "light"

    document.getElementsByTagName("html")[0].dataset.theme = theme

  })

export default router