<template>
  <div>
    <div class=" hero">
      <div>
        <h1>The Simple way to broadcast</h1>
      </div>
      <div>
        <p>Broadcast your live events with a simple interface.</p>
      </div>
    </div>
    <section class="container-fluid banner-image"></section>
    <div class="container">
      <div class="features">
        <article>
          <YoutubeIcon :size="iconSize" />
          <p>
            Broadcast to Streaming Services like Youtube
          </p>
          <p class="blurb">Easily broadcast your live streams to popular platforms like YouTube, reaching a global
            audience effortlessly.
          </p>
        </article>
        <article>
          <UsersIcon :size="iconSize" />
          <p>
            Only three clicks to go live
          </p>
          <p class="blurb">
            Start your live stream in just three simple clicks, making it accessible for anyone to use without technical
            expertise.
          </p>
        </article>
        <article>
          <CalendarIcon :size="iconSize" />
          <p>
            Integrates with Youtube events
          </p>
          <p class="blurb">
            Seamlessly schedule and manage your live streams with YouTube Events, ensuring your audience is notified and
            can join easily.
          </p>
        </article>
        <article>
          <VideoIcon :size="iconSize" />
          <p>
            Supports RTSP for IP Cameras.
          </p>
          <p class="blurb">Compatible with any network camera that supports RTSP, providing flexibility and high-quality
            live streaming from various camera options.
          </p>
        </article>
        <article>
          <SmartphoneIcon :size="iconSize" />
          <p>
            Use from your phone
          </p>
          <p class="blurb">Designed for mobile use, simply access the webpage from your phone with no installation
            needed.

          </p>
        </article>
        <article>
          <ServerIcon :size="iconSize" />
          <p>
            Supporst h264 and h265 for UHD
          </p>
          <p class="blurb">Enjoy ultra-high-definition streaming with support for H264 and H265 video codecs.

          </p>
        </article>

      </div>

      <p>
        ReadyCast makes live streaming simple and accessible, allowing you to broadcast effortlessly to platforms like
        YouTube with just three clicks. It integrates seamlessly with YouTube Events for easy scheduling and audience
        notifications. The platform supports RTSP for IP cameras, providing flexibility in camera choices and
        high-quality streaming. Designed with mobility in mind, ReadyCast requires no installation—just access the
        webpage from your phone. Additionally, it supports H264 and H265 video codecs for ultra-high-definition
        streaming, ensuring your content is delivered in the best possible quality.
      </p>

      <h2>Video</h2>
      <p>
        ReadyCast supports any network camera with RTSP capability, allowing you to use a wide range of cameras for your
        live streaming needs. Simply provide the camera's URL to integrate it with our platform. If you're unsure about
        the URL or need assistance with setting up your camera, our support team is here to help. We aim to make the
        setup process as smooth as possible, ensuring high-quality video streaming for your events.
      </p>
      <h2>Audio</h2>
      <p>
        For audio, ReadyCast typically utilizes a feed from your existing audio system, customized to fit your venue's
        unique setup. This approach ensures that the audio quality is tailored to your specific environment, providing
        the best possible experience for your viewers. If you encounter any challenges with your audio setup, our team
        is ready to assist you. We can help you configure your audio feed to work seamlessly with ReadyCast,
        guaranteeing clear and professional sound for your live streams.
      </p>
    </div>
  </div>
</template>

<script>
import {
  YoutubeIcon,
  UsersIcon,
  SmartphoneIcon,
  CalendarIcon,
  VideoIcon,
  ServerIcon
} from "@zhuowenli/vue-feather-icons";

export default {
  name: "features",
  components: {
    YoutubeIcon,
    UsersIcon,
    CalendarIcon,
    VideoIcon,
    SmartphoneIcon,
    ServerIcon
  },
  data() {
    return {
      iconSize: "3x"
    }
  }
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url(@/assets/images/mockup-featuring-a-woman-s-hand-holding-an-iphone-11-pro-over-a-neat-desk-2158-el1.png);
  background-size: cover;
  background-position: 30%;
  padding: 100px 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;


  h1 {
    margin-bottom: 20px;
  }

  div {
    text-shadow: 0 0 9px white;
  }
}

.features {
  margin: 80px 0 40px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  justify-content: space-evenly;

  article {
    text-align: center;
    width: 330px;

    display: block;

    p {
      margin-top: 20px;
      margin-bottom: 0;

      &.blurb {
        font-size: 0.8em;
      }
    }
  }
}
</style>