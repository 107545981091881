<template>
  <div class="container" data-theme="light">
    <div class="grid">
      <div>
        <h3>Simple to use</h3>
        <UsersIcon size="4x" />
        <p>Only 2 clicks to go live, non-techies can use it</p>
      </div>
      <div>
        <h3>Integrates to Youtube</h3>
        <YoutubeIcon size="4x" />
        <p>Schedule your events in Youtube and pick the event to broadcast to in {{ $appName }}</p>
      </div>
      <div>
        <h3>No Additional Hardware</h3>
        <HardDriveIcon size="4x" />
        <p>We can supply the camera and help with audio</p>
      </div>
    </div>
  </div>
</template>

<script>
import { YoutubeIcon, UsersIcon, HardDriveIcon } from '@zhuowenli/vue-feather-icons'

export default {
  name: "Features",
  components: {
    YoutubeIcon, UsersIcon, HardDriveIcon
  }

}
</script>

<style lang="scss" scoped>
.container {

  background-color: white;
  color: var(--color);
  max-width: initial;
  margin: 0;
  padding: 30px 0;

  .grid div {
    padding: 30px;
    text-align: center;

    h3 {
      margin-bottom: 5px;
    }

    svg.feather {
      margin: 10px;
    }

    p {
      font-size: 0.9em;
    }

  }
}
</style>